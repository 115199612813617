let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Zhengwei Li",
    "role": "Software Engineer",
    // "linkedinId":"Your LinkedIn Id",
    // "skypeid": "Your skypeid",
    "roleDescription": ["Zhengwei Li", "Full Stack Developer", "Hard Worker", "Quick Learner"],
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/zhengwei-li/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/zhengwei-li",
          "className":"fa fa-github"
        },
        // {
        //   "name":"skype",
        //   "url":"http://twitter.com/rbhatia46",
        //   "className":"fa fa-twitter"
        // }
      ],
    // "aboutme":"I am currently a pre-final year student at The LNM Institute of Information Technology and pursuing my B.Tech from here. I am a self taught Full Stack Web Developer, currently diving deeper into Machine Learning. I believe that to be successful in life, one needs to be obsessive with their dreams and keep working towards them.",
    // "address":"India",
    // "website":"https://zhengwei.li",
    "education":[
      {
        "UniversityName":"Stevens Institute of Technology",
        "specialization":"Master of Engineering in Electrical Engineering",
        "MonthOfStart":"Aug",
        "YearOfStart":"2013",
        "MonthOfPassing":"May",
        "YearOfPassing":"2015",
        "Achievements":"GPA: 3.767/4"
      },
      {
        "UniversityName":"Hebei Universicy of Science and Technology",
        "specialization":"Bachelor of Engineering in Electrical Engineering",
        "MonthOfStart":"Sep",
        "YearOfStart":"2009",
        "MonthOfPassing":"Jun",
        "YearOfPassing":"2013",
        "Achievements":"GPA: 82/100"
      }
    ],
    "works":[
      {
        "Name":"Software Engineer @ Facebook",
        "Location":"NYC, NY",
        "MonthOfStart":"May",
        "YearOfStart":"2020",
        "MonthOfLeaving":"Present",
        // "YearOfLeaving":"2018",
        "Responsibilities": [
          
        ]
      },
      {
        "Name":"Software Engineer @ E-Commerce, Newell Brands",
        "Location":"Hoboken, NJ",
        "MonthOfStart":"Jul",
        "YearOfStart":"2018",
        "MonthOfLeaving":"May",
        "YearOfLeaving":"2020",
        "Responsibilities": [
          "Work on an agile team to build a next generation E-Commerce system, New Stack, which including PIM, CMS, OMS, Commerce Tool and other E-commerce components.",
          "Develop core extensions based on the ElasticPath to fit the business requirements by using <b>Java</b> & <b>Spring</b>",
          "Design and implement a high-scale and serverless integration service to orchestrate multiple platforms by using <b>AWS Lambda</b> and <b>S3</b>.",
          "Develop a <i>WhereToBuy</i> module to offer customers prices from various retailers by using <b>Node.js</b>, <b>Serverless</b> framework to build a web crawler to scrape data.",
          "Design and develop an elegant and responsive web user interface by using <b>Vue.js</b>, <b>Bootstrap</b>, <b>axios</b>, and <b>Webpack</b>; visualize location-based results on the map by using Google Map API.",
          "Build CI/CD pipeline to automatically build, test & deploy by using <b>Travis CI</b>."
        ]
      },
      {
        "Name":"Software Engineer @ XO by Newell Brands, Newell Brands",
        "Location":"Hoboken, NJ",
        "MonthOfStart":"Jan",
        "YearOfStart":"2018",
        "MonthOfLeaving":"Jul",
        "YearOfLeaving":"2018",
        "Responsibilities": [
          "Worked on the core platform team to build a <b>microservice</b> architecture platform by <b>Kubernetes(K8s)</b> to support and host all XO application projects by exposing <b>RESTful APIs</b> following HAL/HATEOAS API standard.",
          "Designed and implemented the authentication, identity management services by using <b>Java</b>, <b>Spring Boot</b>, <b>Spring Cloud</b>, <b>OAuth</b>, <b>JWT</b>.",
          "Designed and develop the pipeline of label creation for <i>Yankee Candle Video Label App</i> by using <b>Python</b>, <b>Flask</b>, <b>Apache Kafka</b>, and <b>AWS S3</b>.",
          "Built the <i>SmartLabel</i> application management frontend app by using React and Redux to generate printable labels and show where label scan events occurred on a map in time.",
          "Developed a pipeline to deploy web app to <b>AWS S3</b>, cache & invalidate files by leveraging <b>Amazon CloudFront</b>.",
          "Implemented a scalable logging and analytics service by using <b>Elasticsearch</b>, <b>Grafana</b> and <b>Kibana</b>."
        ]
      },
      {
        "Name":"Web Development Engineer @ Bond Gifting Inc",
        "Location":"NYC, NY",
        "MonthOfStart":"Jan",
        "YearOfStart":"2016",
        "MonthOfLeaving":"Jan",
        "YearOfLeaving":"2018",
        "Responsibilities": [
          "Worked on building Bond 1.0 System and Bond 2.0 System which are a suite of software applications allowing customers to use their phone or a computer to send beautiful handwritten notes (written by our robots) on customizable stationery.",
          "Maintained the Bond 1.0 System which includes RESTful API 1.0, frontend app and backoffice.",
          "Optimized the bulk order tool inside the Bond 1.0 System using <b>Amazon SQS</b> as the message queue and <b>lazy-loading</b> pattern, which increases at least 25% efficiency.",
          "Built the Bond RESTful API 2.0 with modular design by using <b>Node.js</b>, <b>Hapi.js</b> and <b>MongoDB</b>.",
          "Developed the Bond internal management tool 2.0 by using <b>React</b> to manage the users, orders and stationeries."
        ]
      }
    ],
    "skillsDescription":"Your skills here",
    "skills":[
      {
        "category":"Coding Language",
        "skills": "Java, JavaScript, Hack(PHP), Python, HTML, CSS, SASS, SQL"
      },
      {
        "category":"Backend",
        "skills": "Spring Boot, Hapi.js, Express.js, Laravel, Flask, MySQL, MongoDB, Kafka, Elasticsearch, Kafka, Redis"
      },
      {
        "category":"Frontend",
        "skills": "React, Vue.js, Angular.js, Redux, jQuery, Backbone.js"
      },
      {
        "category":"Architecture",
        "skills": "MVC, modular, micro-service, serverless"
      },
      {
        "category":"Development and Operation",
        "skills": "AWS, Docker, Kubernetes, Vagrant, Git, Linux, macOS"
      }
    ],
    "portfolio":[
      {
        "name":"project1",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      },
      {
        "name":"project2",
        "description":"mobileapp",
        "imgurl":"images/portfolio/project.jpg"
      },
      {
        "name":"project3",
        "description":"mobileapp",  
        "imgurl":"images/portfolio/project2.png"
      },
      {
        "name":"project4",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      }
    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]
  }
  
  export default resumeData