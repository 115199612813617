import React, { Component } from 'react';
import { Markup } from 'interweave';
export default class Resume extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="resume">
        <div className="row projects">
          <div className="three columns header-col" >
            <h1><span>Work Experience</span></h1>
          </div>
          <div className="nine columns main-col">
            {
              resumeData.works && resumeData.works.map((item, index) => {
                return (
                  <div className="row item" key={index}>
                    <div className="twelve columns">
                      <h3>{item.Name}</h3>
                      <p className="info">
                        {item.Location}
                        <span>&bull;</span> <em className="date">{item.MonthOfStart} {item.YearOfStart}</em> - <em className="date">{item.MonthOfLeaving} {item.YearOfLeaving}</em>
                      </p>
                      <ul className="responsibilities">
                        {item.Responsibilities.map((responsibility, index) => {
                          return (
                            <Markup tagName="li" key={index} content={"&bull;  " + responsibility} /> 
                          )
                        })}
                      </ul>
                      <p>
                        {item.Achievements}
                      </p>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>             
        <div className="row skill">
          <div className="three columns header-col">
            <h1><span>Skills</span></h1>
          </div>
          <div className="nine columns main-col">
            {/* <p>
              {resumeData.skillsDescription}
            </p> */}
            {/* <div className="bars"> */}
              <ul className="skills">
                {
                  resumeData.skills && resumeData.skills.map((item, index) => {
                    return (
                      <li key={index}>
                        <span ><b>{item.category}: </b></span><em>{item.skills}</em>
                      </li>
                    )
                  })
                }
              </ul>
            {/* </div> */}
          </div>
        </div>
        <div className="row education">
          <div className="three columns header-col">
            <h1><span>Education</span></h1>
          </div>
          <div className="nine columns main-col">
            {
              resumeData.education && resumeData.education.map((item, index) => {
                return (
                  <div className="row item" key={index}>
                    <div className="twelve columns">
                      <h3>{item.UniversityName}</h3>
                      <p className="info">
                        {item.specialization}
                        <span>&bull;</span> <em className="date">{item.MonthOfStart} {item.YearOfStart}</em> - <em className="date">{item.MonthOfPassing} {item.YearOfPassing}</em></p>
                      <p>
                        {item.Achievements}
                      </p>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div> 
      </section>
    );
  }
}