import React, { Component } from 'react';
import Typing from 'react-typing-animation';
export default class Header extends Component {
  render() {
    let resumeData = this.props.resumeData;
    let descriptions = [];
    for (var i = 0; i < resumeData.roleDescription.length; i++) {
      descriptions.push(
            <div key={Math.random()}>
                <h1>{resumeData.roleDescription[i]}.</h1>
                <Typing.Delay ms={1000} />
                <Typing.Backspace count={resumeData.roleDescription[i].length + 2} />
            </div>
        );
    }
    return (
      <React.Fragment>
        <header id="home">
          <nav id="nav-wrap">
            <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
            {/* eslint-disable-next-line */}
            <a className="mobile-btn" href="#" title="Hide navigation">Hide navigation</a>
            <ul id="nav" className="nav">
              <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
              {/* <li><a className="smoothscroll" href="#about">About</a></li> */}
              <li><a className="smoothscroll" href="#resume">Resume</a></li>
              {/* <li><a href="https://coder.li" rel="noopener noreferrer" target="_blank">Blog</a></li> */}
              {/* <li><a className="smoothscroll" href="#portfolio">Works</a></li> */}
              {/* <li><a className="smoothscroll" href="#testimonials">Testimonials</a></li> */}
              {/* <li><a href="https://coder.li/contact/" rel="noopener noreferrer" target="_blank">Contact</a></li> */}
            </ul>
          </nav>

          <div className="row banner">
            <div className="banner-text">
              <h1 className="responsive-headline">I am </h1>
              <Typing loop speed={100} cursor={null}>
                <Typing.Backspace count={1} />
                {descriptions}
              </Typing>
              {/* <h3 style={{ color: '#fff', fontFamily: 'sans-serif ' }}>I am a {resumeData.role}.{resumeData.roleDescription}
              </h3> */}
              {/* <hr />
              <ul className="social">
                {
                  resumeData.socialLinks && resumeData.socialLinks.map(item => {
                    return (
                      <li key={item.name}>
                        <a href={item.url} rel="noopener noreferrer" target="_blank"><i className={item.className}></i></a>
                      </li>
                    )
                  }
                  )
                }
              </ul> */}
            </div>
          </div>
          <p className="scrolldown">
            <a className="smoothscroll" href="#resume"><i className="icon-down-circle"></i></a>
          </p>
        </header>
      </React.Fragment>
    );
  }
}